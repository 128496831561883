html {
  font-size: initial;
}

.password-input + .eye-icon {
  display: none;
}

.password-input:focus + .eye-icon {
  display: block !important;
  background-size: contain;
  background-image: url('../assets/icons/eye-closed.svg');
}

.eye-icon:hover {
  background-size: contain;
  background-image: url('../assets/icons/eye-open.svg') !important;
}

.navbar-toggler .icon {
  background-image: url('../assets/icons/menu-active.svg');
  width: 24px;
  height: 24px;
  display: block;
}

.navbar-toggler[aria-expanded='false'] .icon {
  background-image: url('../assets/icons/menu.svg');
}

.logos img {
  width: 180px;
  height: auto;
}

.tooltip-download {
  margin-top: 0.5rem !important;
  margin-left: 3rem !important;
  --mdb-tooltip-bg: #d9d9d9;
}

.tooltip-download .tooltip-inner {
  padding: 0.75rem;
  border-radius: 0.5rem;
  background-color: #d9d9d9 !important;
  color: #00365b !important;
  max-width: 19.125rem;
}

.tooltip-download .tooltip-arrow {
  --mdb-tooltip-bg: #d9d9d9 !important;
  display: block !important;
  left: -7rem !important;
}

.tooltip-qrcode {
  left: 64px !important;
  z-index: 9;
  transform: initial !important;
}

.download:hover {
  background-color: #00365b !important;
}

.qrcode {
  background-image: url('../assets/home/qrcode.svg');
  background-size: contain;
  background-repeat: no-repeat;
}

.qrcode:hover {
  background-image: url('../assets/home/qrcode-active.png');
}

.bg-image-vertical {
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: right center;
  background-size: auto 100%;
}

input:focus {
  outline: none !important;
  border: 1px solid #0068b0 !important;
}

ul .list-group-item {
  border: 0;
}

#sidebarMenu a {
  font-size: 0.875rem;
  color: #3f4855;
}

.sidebar-order-icon {
  width: 18px;
  height: 18px;
  background-size: contain;

  background-image: url('../assets/icons/orders.svg');
}

.sidebar-active .sidebar-order-icon {
  background-image: url('../assets/icons/orders-active.svg');
}

.sidebar-devices-icon {
  width: 18px;
  height: 18px;
  background-size: contain;

  background-image: url('../assets/icons/devices.svg');
}

.sidebar-active .sidebar-devices-icon {
  background-image: url('../assets/icons/devices-active.svg');
}

.sidebar-invitation-icon {
  width: 18px;
  height: 18px;
  background-size: contain;

  background-image: url('../assets/icons/invitation.svg');
}

.sidebar-active .sidebar-invitation-icon {
  background-image: url('../assets/icons/invitation-active.svg');
}

.sidebar-enterprise-icon {
  width: 18px;
  height: 18px;
  background-size: contain;

  background-image: url('../assets/icons/folder.svg');
}

.sidebar-active .sidebar-enterprise-icon {
  background-image: url('../assets/icons/folder-active.svg');
}

.sidebar-dashboard-icon {
  width: 18px;
  height: 18px;
  background-size: contain;

  background-image: url('../assets/icons/home.svg');
}

.sidebar-active .sidebar-dashboard-icon {
  background-image: url('../assets/icons/home-active.svg');
}

.sidebar-active {
  color: #0068b0 !important;
}

/* table */
table tbody tr:hover {
  background-color: #f5f7fa;
}

table tbody th a:hover {
  text-decoration: underline;
}

table .status {
  border-radius: 2rem;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

table .status-active {
  color: #064e00;
  background-color: #e4f7dd;
}

table .status-inactive {
  color: #4e0900;
  background-color: #f7e9dd;
}

table .status-current {
  color: #00426f;
  background-color: #deeaff;
}

.enterprise-info input {
  background-color: #f8fbff;
  border: 1px solid #c9defc;
  color: #1d2939 !important;
  font-size: 0.875rem;
  width: 36.5rem;
  height: 2.25rem;
}

.enterprise-info label.required::before {
  content: '';
  background-image: url('../assets/icons/required.svg');
  width: 0.5rem;
  height: 0.5rem;
  margin-right: 0.25rem;
  margin-top: -0.3rem;
}

.enterprise-tabs .nav-tabs .nav-link.active {
  color: #0068b0;
  border-color: #0068b0;
}

.enterprise-tabs .nav-tabs .nav-link {
  color: #3f4855;
  border-bottom: 4px solid transparent;
}

.faq-tabs .nav-tabs .nav-link.active,
.faq-tabs .nav-tabs .nav-link:hover {
  color: #0068b0;
  border-color: #0068b0;
  background-color: white;
}

.faq-tabs .nav-tabs .nav-link {
  color: #3f4855;
  border-bottom: 4px solid transparent;
}

.news-tabs .nav-tabs .nav-link.active,
.news-tabs .nav-tabs .nav-link:hover {
  color: #0068b0;
  border-color: #0068b0;
  background-color: white;
  padding-bottom: 0.25rem !important;
  font-weight: 700 !important;
}

.news-tabs .nav-tabs .nav-link {
  color: #000000;
  font-weight: normal !important;
  border-bottom: 4px solid transparent;
}

.news-content img {
  align-self: center;
  width: 100%;
}

.fee-plan-tabs .nav-item .nav-link.active {
  color: #0068b0 !important;
  background-color: white !important;
}

.fee-plan-tabs .nav-item .nav-link:hover {
  background-color: #0068b0 !important;
  color: #ffffff !important;
}

.fee-plan-tabs .nav-item .nav-link.active:hover {
  color: #0068b0 !important;
  background-color: white !important;
}

.home-banner .carousel .carousel-inner .carousel-item {
  height: 39.31rem;
  width: 56.69rem;
}

.home-banner .carousel .carousel-inner .banner-1 {
  background-image: url('../assets/home/banner-1.svg');
  background-size: contain;
}

.home-banner .carousel .carousel-inner .banner-2 {
  background-image: url('../assets/home/banner-2.svg');
  background-size: contain;
}

.home-banner .carousel .carousel-inner .banner-3 {
  background-image: url('../assets/home/banner-3.svg');
  background-size: contain;
}

main .carousel-indicators > div button {
  margin: 0;
  border: 0;
  height: 4px;
  opacity: 1;
  background-color: #c5dcec !important;
}

.home-main .carousel-indicators > div button {
  width: 16.67%;
}

.case-main .carousel-indicators > div button {
  width: 50%;
}

main .carousel-indicators .active {
  background-color: #0068b0 !important;
}

main .carousel-indicators .carousel-control-prev,
main .carousel-indicators .carousel-control-next {
  width: 3.5rem;
  height: 3.5rem;
  opacity: 1;
  position: initial;
  border: none;
  margin: 0;
}

main .carousel-indicators .carousel-control-prev {
  background-image: url('../assets/home/prev.svg');
  background-size: contain;
  background-repeat: no-repeat;
}
main .carousel-indicators .carousel-control-prev:hover {
  background-image: url('../assets/home/next.svg');
  background-size: contain;
  transform: rotate(180deg);
}

main .carousel-indicators .carousel-control-next {
  background-image: url('../assets/home/prev.svg');
  background-size: contain;
  transform: rotate(180deg);
  background-repeat: no-repeat;
}
main .carousel-indicators .carousel-control-next:hover {
  background-image: url('../assets/home/next.svg');
  background-size: contain;
  transform: rotate(0deg);
}

footer .footer-container {
  background-color: #0068b0;
  display: flex;
  padding: 2.5rem 4.5rem 4rem 4.5rem;
  justify-content: space-evenly;
}

footer .footer-container .footer-top {
  display: flex;
  gap: 5.5rem;
}

.footer-top .footer-nav {
  display: flex;
  font-size: 14px;
  font-weight: 500;

  gap: 1rem;
  flex-direction: column;

  border-right: 1px solid rgba(255, 255, 255, 0.6);
  padding-right: 6rem;
}

.footer-top a {
  text-decoration: none;
}

.footer-nav a {
  color: #ffffff;
  text-decoration: none;
  flex-grow: 0;
}

.footer-container .footer-logo {
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  color: #ffffff;
  display: flex;
  gap: 10px;
}

.footer-logo img {
  width: 134px;
  height: 42px;
}

.footer-container .footer-medias {
  display: flex;
  gap: 30px;
}

.footer-media img {
  width: 24px;
  height: 24px;
}

.footer-right {
  margin-left: 22.9375rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer-container .footer-bottom {
  display: flex;
  justify-content: space-between;

  margin-left: 5.25rem;
}

.footer-right .footer-bottom-left {
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.6);
}

.footer-bottom .footer-bottom-right {
  display: flex;
  flex-direction: column;

  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}

.footer-bottom-right a {
  margin-top: 28px;
}

.footer-bottom-right address {
  font-style: normal;
}

.footer-bottom-right a {
  color: #ffffff;
  text-decoration: none;
}

.auth-tip {
  visibility: hidden;
}

@media (min-width: 1025px) {
  .h-custom-2 {
    height: 100%;
  }
}

.anim-x-scroller {
  animation: x-scroller 22s linear infinite alternate;
  float: left;
}

@keyframes x-scroller {
  0% {
    margin-left: 0;
    transform: translateX(0);
  }

  5% {
    margin-left: 0;
    transform: translateX(0);
  }

  95% {
    margin-left: 100%;
    transform: translateX(-100%);
  }

  100% {
    margin-left: 100%;
    transform: translateX(-100%);
  }
}

/*models page*/
.folder-sample:hover,
.folder-home:hover,
.sidebar-item:hover,
.folder-item:hover,
.folder-sample.active,
.folder-home.active,
.sidebar-item.active,
.folder-item.active {
  background-color: #0068b00d !important;
}

.folder-sample.active .fs-14,
.folder-home.active .fs-14,
.sidebar-item.active .fs-14,
.folder-item.active {
  color: #1c2124 !important;
}

.location-info:before {
  display: inline-block;
  content: '';
  background-image: url('../assets/models/icons/location.svg');
  background-size: contain;
  width: 0.75rem;
  height: 0.75rem;
}

.view-grid {
  background-size: contain;
  background-image: url('../assets/models/icons/view-grid.svg');
}

.view-grid-active {
  background-size: contain;
  background-image: url('../assets/models/icons/view-grid-active.svg') !important;
}

.view-list {
  background-size: contain;
  background-image: url('../assets/models/icons/view-list.svg');
}

.view-list-active {
  background-size: contain;
  background-image: url('../assets/models/icons/view-list-active.svg') !important;
}

.show .dropdown-icon {
  transform: rotate(180deg);
  transition: all ease 0.5s;
}

.dropdown-icon {
  transform: rotate(0);
  transition: all ease 0.5s;
}

.dropdown-menu.dropdown-menu-more {
  border-radius: 0.25rem;
  box-shadow: 0px 10px 32px 0px #00365b1a;
}
.dropdown-menu-more .dropdown-item {
  height: 2.25rem;
}
.dropdown-menu-more .dropdown-item:hover,
.dropdown-menu-more .dropdown-item:focus {
  background-color: rgba(0, 104, 176, 0.08);
}
.dropdown-menu-more .dropdown-item:hover span,
.dropdown-menu-more .dropdown-item:focus span {
  font-weight: 700;
}

.entry:hover {
  background-color: rgba(0, 104, 176, 0.08);
}

.entry-active div {
  color: #00365b !important;
}

.i18n-menu .entry-active div {
  font-weight: bold;
}

.entry-active .check-icon {
  visibility: visible;
}

.check-icon {
  visibility: hidden;
}

.sort-menu:hover {
  border: 1px solid #0068b0;
  background: #0068b014 !important;
}

/* Model Pages */
.models-list-container.models-list-container {
  width: 100%;
  height: auto;
  min-height: calc(100% - 11rem);
  box-sizing: border-box;
  overflow-x: auto;
}

.models-list-container .models-list-row-title {
  width: calc(21.45vw - 5rem);
  min-width: calc(284px - 5rem);
}

.models-list-container .models-list-row-user {
  width: calc(12vw - 1rem);
  min-width: calc(160px - 1rem);
}

.models-list-container table > :not(caption) > * > * {
  padding: 1.25rem 0 1.25rem 1rem;
}

.models-list-container table {
  min-width: 1034px;
  border-color: #00365b0d;
}

.models-list-container table th {
  padding-right: 1rem;
  white-space: nowrap;
}

.models-list-container table tbody .table-row-active,
.models-list-container table tbody tr:hover {
  background-color: rgba(0, 104, 176, 0.05);
  position: relative;
}

/*.models-list-container table .name-column::after,*/
.models-list-container table tbody tr:hover .models-list-check {
  content: '';
  width: 1rem;
  height: 1rem;
  display: inline-block;
  position: absolute;
  left: 1rem;
  top: 50%;
  background-image: url('../assets/models/icons/list-uncheck.svg');
  background-size: contain;
  transform: translateY(-50%);
  cursor: pointer;
}

.models-list-container table tbody tr:hover .dropdown {
  opacity: 1 !important;
}
.models-list-container table tbody tr .dropdown-menu {
  display: none;
}
.models-list-container table tbody tr:hover .dropdown-menu.show {
  display: block;
}

.models-list-container table tbody .table-row-active:hover .models-list-check,
.models-list-container table tbody .table-row-active .models-list-check {
  content: '';
  width: 1rem;
  height: 1rem;
  display: inline-block;
  position: absolute;
  left: 1rem;
  top: 50%;
  background-image: url('../assets/icons/check-fill.svg');
  background-size: contain;
  transform: translateY(-50%);
  cursor: pointer;
}

.model-check-icon {
  background: url('../assets/models/icons/uncheck.svg') no-repeat;
  background-size: contain;
}

#models-container .table-row-active .model-check-icon,
.active .model-check-icon,
.list-group-item:hover .model-check-icon,
.active .model-check-icon {
  display: block;
  background: url('../assets/icons/check-fill.svg') no-repeat;
  background-size: contain;
}

#models-container .model-card {
  width: calc(16.66% - 1.67rem);
  min-width: 200px;
}

#models-container .model-check-icon {
  display: none;
}

#models-container .model-card:hover .model-check-icon {
  display: block;
}

.list-group-item.active,
#models-container .table-row-active,
.modal-folders .list-group-item:hover {
  background: #0068b014 !important;
}

#models-container .model-card:hover {
  box-shadow: 0 10px 48px 0 rgba(0, 54, 91, 0.2);
}

#models-container .model-card:hover .view-more {
  display: block;
}

/* border有位移，outline对圆角的兼容性不够好 */
/* #models-container .table-row-active {
  border: 1px solid #0068b0 !important;
} */
#models-container .table-row-active:after {
  content: '';
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  border-radius: 8px;
  border: 1px solid #0068b0;
  pointer-events: none;
  z-index: 0;
}

#models-container .table-row-active .view-more {
  display: none;
}

@media (max-width: 1000px) {
  .models-list-container.models-list-container {
    min-height: calc(100% - 13rem);
  }
  .models-list-top {
    flex-wrap: wrap;
  }
  .models-list-top-left {
    width: 100%;
  }
  .models-list-top-right {
    margin-top: 8px;
  }
  .models-list-top-right .models-list-top-right-first {
    margin-left: 0 !important;
  }
}

@media (max-width: 744px) {
  .models-list-container.models-list-container {
    min-height: auto;
  }
  .search-name.search-name {
    height: 20px;
  }
}

.modal-folders {
  border: 1px solid rgba(0, 54, 91, 0.1);
}

.modal-folders .list-group-item {
  border-bottom: 1px solid rgba(0, 54, 91, 0.1);
}

.modal-folders .list-group-item:last-child {
  border-bottom: none;
}

.search-name {
  height: 1.25rem;
  box-sizing: content-box;
}

.search-name::placeholder {
  color: rgba(0, 54, 91, 0.6);
}

.search-name::-webkit-search-cancel-button {
  -webkit-appearance: none;
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  margin-right: 1.25rem;
  background: url('../assets/models/icons/cancel.svg') no-repeat;
  background-size: contain;
}

.view-more {
  background: url('../assets/models/icons/more-fill.svg') no-repeat;
  background-size: contain;
  -webkit-appearance: none !important;
  display: none;
}

.view-list-more {
  background: url('../assets/models/icons/list-more.svg') no-repeat;
  background-size: contain;
  -webkit-appearance: none !important;
}

.view-more.show,
.view-more:hover {
  display: block;
  background: url('../assets/models/icons/more-focus.svg') no-repeat;
  background-size: contain;
}

.view-list-more.show,
.view-list-more:hover {
  background: url('../assets/models/icons/list-more-active.svg') no-repeat;
  background-size: contain;
}

.dropup .view-list-more.dropdown-toggle:after {
  display: none;
}

.breadcrumb-item::before {
  content: '/';
  padding-right: 0.75rem !important;
  height: 1.25rem !important;
  display: inline-block;
}

.breadcrumb-item {
  padding-left: 0.75rem !important;
  height: 1.5rem !important;
  display: flex;
  color: #809aad !important;
}

.sub-nav-breadcrumb-home,
.breadcrumb-item a {
  color: #809aad !important;
}

.sub-nav-breadcrumb-home.active,
.breadcrumb-item.active {
  font-weight: bold;
  color: #394953 !important;
}

.dark-mode {
  background-color: #272e32 !important;
}
/* header.dark-mode {
  position: absolute !important;
  top: 0;
  right: 0;
  left: 0;
  padding: 0;
  height: 0;
  background: none;
}
header.dark-mode .container-fluid {
  height: 0;
}
header.dark-mode .header-left,
header.dark-mode .header-right {
  position: absolute;
  top: 28px;
  margin-top: 0 !important;
  background-color: #272e32;
  transform: translateY(-50%);
}
header.dark-mode .header-left {
  left: 0;
  margin-left: 1.5rem !important;
}
header.dark-mode .header-right {
  right: 0;
} */

.dark-mode .dropdown-toggle {
  color: #c2c7cb !important;
}

.dark-mode .customer-name,
.dark-mode .home-register,
.dark-mode .home-login {
  color: white !important;
}

.dark-mode .home-register {
  background-color: transparent !important;
  border-color: white !important;
}

.landing-card:hover {
  border-color: #0068b0 !important;
  box-shadow: 0 10px 40px 0 rgba(0, 54, 91, 0.1);
}

.with-folder-icon {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 0.875rem !important;
  color: #00365b99 !important;
}

.with-folder-icon::before {
  content: '';
  background: url('../assets/models/icons/folder-icon.svg') no-repeat;
  background-size: contain;
  width: 1rem;
  height: 1rem;
  display: inline-block;
  margin-right: 0.75rem;
  flex-shrink: 0;
}

.sidebar-tree-view .accordion-button:after {
  width: 0.875rem;
  height: 0.875rem;
  background-size: contain;
}

.sidebar-tree-view .accordion-button:not(.collapsed):after,
.sidebar-tree-view .accordion-button:after {
  background-image: url('../assets/icons/sidebar-arrow.svg');
}

.select-user {
  max-height: 8rem;
}

.scroller {
  overflow-y: auto;
}

.scroller::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
.scroller::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.scroller::-webkit-scrollbar-thumb {
  background: #00365b33;
}

.btn-select-all .active {
  background-image: url('../assets/icons/check-fill.svg') !important;
}

.navbar-item {
  margin-left: -1rem;
  margin-right: -1rem;
  padding-left: 1rem;
}

.navbar-item:hover {
  background: #0068b014;
  color: #00365b;
}

.navbar-item.active {
  font-weight: bold;
  color: #00365b;
}

.navbar-item.active:hover {
  background: inherit;
  color: inherit;
}

.models-section,
.models-tip {
  background-color: #f8fbff !important;
}

.models-section {
  position: relative;
}

.pagination .page-link {
  font-size: 0.75rem;
  color: #00365b99;
}

.pagination {
  display: flex;
  align-items: center;
}

.pagination .page-prev {
  margin-right: 0.75rem;
}

.pagination .page-next {
  margin-left: 0.75rem;
}

.pagination .active .page-link {
  background-color: #0068b0;
  color: white !important;
}

.tooltip-name.tooltip-name .tooltip-inner {
  margin: 0;
  padding: 0.5rem;
  max-width: 10.75rem;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.125rem;
  text-align: left;
  background-color: #000;
  border-radius: 0.25rem;
  box-shadow: 0px 10px 32px 0px #00365b1a;
}

.login-check.login-check.login-check.login-check {
  margin-top: 1rem;
  margin-bottom: 0.75rem;
  min-height: 1.375rem;
}
.login-check.login-check.login-check.login-check .form-check-input {
  margin-top: 0.14rem;
  margin-right: 4px;
  width: 13.33px;
  height: 13.33px;
  background: none;
  border-radius: 2.67px;
  border: 1px solid #d5dbe2 !important;
  outline: none;
  box-shadow: none;
}
.login-check.login-check.login-check.login-check .form-check-input:checked {
  background-color: #0068b0;
  border-color: #0068b0 !important;
}
.login-check.login-check.login-check.login-check .form-check-input:before {
  display: none;
}
.login-check.login-check.login-check.login-check .form-check-input:after {
  width: 4.5px;
  height: 10px;
}
.login-check.login-check.login-check.login-check .form-check-input:checked:after {
  background-color: #0000;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  margin-top: -0.8px;
  margin-left: 3px;
  display: block;
  transform: rotate(45deg);
}
.login-check.login-check.login-check.login-check .form-check-label {
  padding-left: 0;
  font-weight: 400;
  font-size: 0.875rem;
  color: #343b46;
  line-height: 1.375rem;
  letter-spacing: 0;
}
.login-check.login-check.login-check.login-check .form-check-label a {
  margin: 0 2px;
  color: #0068b0;
  text-decoration: underline;
}

@media only screen and (max-width: 1780px) {
  #models-container .model-card {
    width: calc(25% - 1.5rem);
  }
}
